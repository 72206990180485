<template>
  <div class="row">
    <div class="col-12">
      <secondments-map-widget report-only @go-to-shop="goToShop" />
    </div>
    <div v-if="isOnlyMaps" class="col-12">
      <risk-level-widget horizontal />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SecondmentsMapWidget from "@/view/content/widgets/dashboard/SecondmentsMapWidget.vue";
import RiskLevelWidget from "@/view/content/widgets/dashboard/RiskLevelWidget.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    SecondmentsMapWidget,
    RiskLevelWidget
  },
  computed: {
    ...mapGetters("user", ["isOnlyMaps"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Risk Report") }]);
  },
  methods: {
    goToShop({ country }) {
      this.$router.push({
        name: "reports",
        params: country,
        query: { iso: country }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .custom-bg {
  background: var(--primary) !important;
}

::v-deep .dropdown-menu .active>.dropdown-item,
::v-deep .dropdown-menu .active:hover>.dropdown-item {
  background-color: #003774;
  color: white;
}
</style>
