var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('secondments-map-widget', {
    attrs: {
      "report-only": ""
    },
    on: {
      "go-to-shop": _vm.goToShop
    }
  })], 1), _vm.isOnlyMaps ? _c('div', {
    staticClass: "col-12"
  }, [_c('risk-level-widget', {
    attrs: {
      "horizontal": ""
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }